import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../styles';
import { CloseIcon, IconButton } from 'loplat-ui';
import { StyledButton } from '@F/StyledButton/styles';
import i18next from 'i18next';
import { gtagEvent } from '@function/gtag';

const SUPPORT_LANGUAGES = ['en', 'ko'] as const;
interface Props {
  closeModal: () => void;
}
const LocaleModal = ({ closeModal }: Props) => {
  const { t } = useTranslation('modal/locale');

  const changeLanguage = (lang: typeof SUPPORT_LANGUAGES[number]) => {
    gtagEvent({ eventName: '언어변경', parameter: { term: lang } });
    document.documentElement.lang = lang;
    i18next.changeLanguage(lang);
  };

  return (
    <>
      <S.ModalTitle>
        <h3>{t('title')}</h3>
        <IconButton onClick={closeModal} color='ghost'>
          <CloseIcon size={16} />
        </IconButton>
      </S.ModalTitle>

      <S.ModalBody>
        <S.ButtonListWrapper>
          {SUPPORT_LANGUAGES.map((locale) => (
            <StyledButton
              key={locale}
              onClick={() => changeLanguage(locale)}
              focused={locale === i18next.language}
              fullWidth
            >
              {t(locale)}
            </StyledButton>
          ))}
        </S.ButtonListWrapper>
      </S.ModalBody>
    </>
  );
};

export default LocaleModal;
