import axios, { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import CONFIG from '@config/';
import AUTH_DATA from '@data/auth';
import HTTPHeaderController from '@function/auth/authorization';
import { reissueToken } from '../function/auth/token';
import TokenController from '@function/auth/tokenStorage';

const instance = axios.create({
  baseURL: CONFIG.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  maxRedirects: 5,
});

const clientHeader = new HTTPHeaderController(instance);

// request
instance.interceptors.request.use(async (request: AxiosRequestConfig) => {
  request.headers = request.headers ?? {};
  const token = TokenController.get();
  if (!token) return request;

  const isStaleToken = AUTH_DATA.TOKEN.STALE_TIME < dayjs().unix() - Number(token.lastUpdatedAt);
  if (isStaleToken) {
    const newToken = await reissueToken(token);
    request.headers.Authorization = `Bearer ${newToken.access_token}`;
    TokenController.set(newToken);
  } else {
    request.headers.Authorization = `Bearer ${token.access_token}`;
  }
  return request;
});

const BAD_AUTH_CODE = [401, 422];

// response
// NOTE: 401일때 토큰을 재발행 후 헤더에 재설정하거나 로그인페이지로 리다이렉트하도록 error throw
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    error.headers = error.headers ?? {};
    const errorCode = error.response?.status ?? 0;
    if (!BAD_AUTH_CODE.includes(errorCode)) return Promise.reject(error);

    const token = TokenController.get();
    if (token) {
      const newToken = await reissueToken(token);
      if (newToken) {
        clientHeader.setAuth(newToken.access_token);
        error.config.headers.Authorization = `Bearer ${token.access_token}`;
        return instance.request(error.config);
      }
    }

    clientHeader.clearAuth();
    TokenController.remove();
    return Promise.reject(error);
  }
);

export default instance;
