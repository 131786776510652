import styled from '@emotion/styled';
import { grayscale800, grayscale900, blue500, grayscale700, spacing } from 'loplat-ui';

export const ModalTitle = styled.div`
  font-size: 1.125rem;
  color: ${grayscale900};
  font-weight: 900;
  text-align: left;
  position: relative;

  white-space: nowrap;

  button {
    position: absolute;
    right: 0;
    top: -0.5rem;
  }

  h3 {
    width: 100%;
    padding-right: 2.25rem;
    strong {
      font-weight: bold;
      color: ${blue500};
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 5px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        background-color: #ebf6fe;
        z-index: -1;
      }
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  p {
    margin: 2.5rem 0;
    font-size: 1.125rem;
    color: ${grayscale900};
    font-weight: 900;
    width: 100%;
    text-align: center;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 0;
    width: 50%;
    height: 3.5rem;
    color: ${grayscale800};
    &:first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const ButtonListWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;

  button {
    &.mt {
      margin-top: 1rem;
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: ${grayscale700};
    text-align: left;
    padding: ${spacing(1)}px 0px;
    margin: 0px;
  }
`;
