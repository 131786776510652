export default {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL ?? '',
  get COOKIE_PREFIX_KEY() {
    switch (this.MODE) {
      case 'staging':
        return 'staging';
      case 'production':
        if (location.host.includes('beta')) return 'beta';
        return 'production';
      default:
        return '';
    }
  },
  KEYS: {
    SECRET: process.env.REACT_APP_SECRET_KEY ?? '',
    USER_SECRET: process.env.REACT_APP_USER_SECRET_KEY ?? '',
    GA: process.env.REACT_APP_M_X_TAG ?? '',
  },

  // NOTE: bitbucket Deployments 에서 설정함.
  // staging | production | localhost
  MODE: process.env.REACT_APP_MODE ?? 'localhost',
  // NOTE: redirection시, 메인 도메인끼리 쿠키를 공유하기 위한 용도
  DOMAIN: (process.env.REACT_APP_MODE ?? 'localhost') === 'localhost' ? 'localhost' : '.loplat.com',
};
