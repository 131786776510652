import styled from '@emotion/styled';
import { grayscale900, blue500 } from 'loplat-ui';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WarningText = styled.p`
  margin: 1rem 0 1.5rem 0;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  word-break: keep-all;
  text-align: center;
  width: 80%;
  color: ${grayscale900};

  strong {
    font-weight: normal;
    color: ${blue500};
  }
`;
