import React, { Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { CloseIcon, IconButton } from 'loplat-ui';
import { StyledButton } from '@F/StyledButton/styles';
import campaignsParams from '@recoil/campaignsParams.atom';
import { FILTERS } from '@data/filterData';
import * as S from '../styles';
import { gtagEvent } from '@function/gtag';
import { useTranslation } from 'react-i18next';

interface Props {
  closeModal: () => void;
}
const FilterModal = ({ closeModal }: Props): React.ReactElement => {
  const { t } = useTranslation('campaign/filter');
  const [params, setParams] = useRecoilState(campaignsParams);
  const { status } = params;

  const filterCampaignsByStatus = (value: string, option: string) => {
    setParams((prev) => ({
      ...prev,
      page: 1,
      status: value,
      label: option,
    }));

    gtagEvent({
      eventName: '필터링',
      parameter: { term: option },
    });

    closeModal();
  };

  return (
    <>
      <S.ModalTitle>
        <h3>{t('title')}</h3>
        <IconButton onClick={closeModal} color='ghost'>
          <CloseIcon size={16} />
        </IconButton>
      </S.ModalTitle>

      <S.ModalBody>
        <S.ButtonListWrapper>
          {FILTERS.map(({ group, status: statusList }) => {
            return (
              <Fragment key={group}>
                {group && <p>{group}</p>}
                {statusList.map(({ option, value }, index) => (
                  <StyledButton
                    key={option}
                    onClick={() => filterCampaignsByStatus(value, option)}
                    focused={option === status}
                    className={index ? 'mt' : ''}
                    fullWidth
                  >
                    <span>{option}</span>
                  </StyledButton>
                ))}
              </Fragment>
            );
          })}
        </S.ButtonListWrapper>
      </S.ModalBody>
    </>
  );
};

export default FilterModal;
