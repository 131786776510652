import dayjs from 'dayjs';
import { encryptRefreshToken, decryptRefreshToken } from '@function/auth/encryption';
import { AuthUserApi } from '@api/index';
import TokenController from './tokenStorage';

export const saveToken = (token: User.Token.Base): User.Token.Base => {
  const refreshToken = encryptRefreshToken(token);
  const newToken = {
    ...token,
    lastUpdatedAt: String(dayjs().unix()),
    refresh_token: refreshToken,
  };
  TokenController.set(newToken);
  return newToken;
};

export const reissueToken = async (oldToken: User.Token.Base): Promise<User.Token.Base> => {
  const decryptedRefreshToken = decryptRefreshToken(oldToken);
  const { data: newToken } = await AuthUserApi.reissueToken({
    refreshToken: decryptedRefreshToken,
  });
  return saveToken({
    access_token: newToken.access_token,
    refresh_token: newToken.refresh_token,
  });
};
