import React, { ReactElement, useEffect } from 'react';
import { Toast } from 'loplat-ui';
import { useSetRecoilState } from 'recoil';
import { AuthUserApi, CampaignApi } from '@api/index';
import { getUserInfo } from '@function/auth/userInfo';
import userInfo from '@recoil/userInfo.atoms';
import CustomModal from '@C/campaigns/CustomModal';
import GlobalStyle from '@/style/GlobalStyle';
import CustomRouter from './routes/index';

function App(): ReactElement {
  const setAuth = useSetRecoilState(userInfo);

  useEffect(() => {
    AuthUserApi.wakeUpServer();
    CampaignApi.wakeUpServer();
    const user = getUserInfo();
    if (user) setAuth(user);
  }, [setAuth]);

  return (
    <>
      <GlobalStyle />
      <Toast />
      <CustomModal />
      <CustomRouter />
    </>
  );
}

export default App;
