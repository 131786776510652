export const CAMPAIGN_STATUS: Campaign.Scheme.Status.Parameter[] = [
  'complete',
  'test',
  'active',
  'inactive',
  'push_not_running',
  'push_reserve',
  'push_sending',
  'push_complete',
  'push_fail',
];

export const defineStatus = (status: string): string => {
  if (status === 'all') return CAMPAIGN_STATUS.join(',');
  if (status === 'proceeding')
    return CAMPAIGN_STATUS.filter((item) => item === 'test' || item === 'active').join(',');
  return status;
};
