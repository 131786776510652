import React, { Suspense } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import modalState from '@recoil/modal.atoms';
import Modal from '@F/Modal';
import LogOutModal from './LogOutModal';
import FilterModal from './FilterModal';
import StatusModal from './StatusModal';
import RedirectionModal from './RedirectionModal';
import LocaleModal from './LocaleModal';

const CustomModal = (): React.ReactElement => {
  const modalStatus = useRecoilValue(modalState);
  const closeModal = useResetRecoilState(modalState);

  return (
    <Suspense fallback={<div />}>
      <Modal>
        {modalStatus.type === 'logout' && <LogOutModal closeModal={closeModal} />}
        {modalStatus.type === 'filter' && <FilterModal closeModal={closeModal} />}
        {modalStatus.type === 'status' && <StatusModal closeModal={closeModal} />}
        {modalStatus.type === 'redirection' && <RedirectionModal closeModal={closeModal} />}
        {modalStatus.type === 'locale' && <LocaleModal closeModal={closeModal} />}
      </Modal>
    </Suspense>
  );
};

export default CustomModal;
