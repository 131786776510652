import axios from 'axios';
import CONFIG from '@config/index';

const authInstance = axios.create({
  baseURL: CONFIG.AUTH_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default authInstance;
