import { atom } from 'recoil';

export type ModalType = 'filter' | 'status' | 'logout' | 'redirection' | 'locale';
type Modal = {
  isOpen: boolean;
  type: ModalType | null;
};

const modalState = atom<Modal>({
  key: 'modalState',
  default: { isOpen: false, type: null },
});

export default modalState;
