import type { AxiosInstance, AxiosPromise } from 'axios';
import { saveToken } from '@function/auth/token';
import { decryptUserInfo } from '@function/auth/encryption';
import type { LoginParameter, RefreshToken } from '@TS/auth';
import HTTPHeaderController from '@function/auth/authorization';
import tokenStorage from '@function/auth/tokenStorage';
import ShareLogin from '@function/shareLogin';
import instance from '@api/client';

const authHeader = new HTTPHeaderController(instance);

class AuthUser {
  instance: AxiosInstance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // NOTE: 로그인이 mounted 될 때, 서버를 깨움 (데이터를 빨리 불러올수있음)
  wakeUpServer(): Promise<void> {
    return this.instance.options('/warmup');
  }

  reissueToken({ refreshToken }: RefreshToken): AxiosPromise<User.Token.Base> {
    return this.instance.post('/oauth/token', {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });
  }

  authorize({ username, password }: LoginParameter): AxiosPromise<User.Token.Base> {
    return this.instance.post('/oauth/token', {
      grant_type: 'password',
      username,
      password,
    });
  }

  async login({ username, password }: LoginParameter): Promise<User.Auth.UserInfo> {
    const { data } = await this.authorize({
      username,
      password,
    });

    const newToken = saveToken(data);
    if (!newToken) throw new Error('로그인 중 토큰 가져올 수 없음');

    authHeader.setAuth(newToken.access_token);
    return decryptUserInfo(newToken);
  }

  logout(): void {
    authHeader.clearAuth();
    tokenStorage.remove();
    ShareLogin.clear();
  }
}

export default AuthUser;
