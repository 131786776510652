import { CAMPAIGN_STATUS } from '@function/campaign/getStatus';

export const LOCATION_FILTERS: Campaign.Scheme.Status.NamedStatus[] = [
  'proceeding',
  'inactive',
  'complete',
];

export const FILTERS = [
  {
    group: '',
    status: [
      {
        option: '전체',
        value: CAMPAIGN_STATUS.join(','),
      },
    ],
  },
  {
    group: '위치캠페인',
    status: [
      { option: '진행', value: ['test', 'active'].join(',') },
      { option: '대기', value: 'inactive' },
      { option: '종료', value: 'complete' },
    ],
  },
  {
    group: '일반캠페인',
    status: [
      { option: '대기', value: 'push_not_running' },
      { option: '발송 예약', value: 'push_reserve' },
      { option: '발송중', value: 'push_sending' },
      { option: '발송 종료', value: 'push_complete' },
      { option: '발송 실패', value: 'push_fail' },
    ],
  },
];
export const TO_MONTHLY_STATUS: Record<
  Extract<Campaign.Scheme.Status.NamedStatus, 'inactive' | 'complete'>,
  keyof Campaign.MonthlyStatus
> = {
  inactive: 'pending',
  complete: 'end',
};
