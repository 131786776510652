import cookies from 'js-cookie';
import AUTH_DATA from '@data/auth';
import CONFIG from '@config/';
import COOKIES from '@data/cookie';
import dayjs from 'dayjs';
import authCookie from './authCookie';

class TokenController {
  static #cookieKey = `${CONFIG.COOKIE_PREFIX_KEY}__${COOKIES.TOKEN}`;

  static get(): User.Token.Base | null {
    const stringifiedToken = authCookie.get(this.#cookieKey);
    return stringifiedToken !== undefined ? JSON.parse(stringifiedToken) : null;
  }
  static remove() {
    authCookie.remove(this.#cookieKey);
    // NOTE: 이전 쿠키도 삭제 (임시 로직) LX-2461
    if (CONFIG.MODE === 'production') {
      [location.host, 'x.loplat.com', '.x.loplat.com', 'beta.x.loplat.com'].forEach((domain) =>
        cookies.remove(this.#cookieKey, { domain })
      );
    }
  }
  static set(newToken: User.Token.Base) {
    authCookie.set(this.#cookieKey, JSON.stringify(newToken), {
      expires: dayjs().add(AUTH_DATA.TOKEN.EXPIRATION_TIME, 'second').toDate(),
    });
  }
}

export default TokenController;
