/* eslint-disable class-methods-use-this */
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { UpdateStatusParams, GetParams } from '@TS/campaigns/api';
import { defineStatus } from '@function/campaign/getStatus';

class Campaign {
  instance: AxiosInstance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  wakeUpServer(): Promise<void> {
    return this.instance.options('/warmup');
  }

  get({
    page,
    size,
    status,
    orderby,
  }: GetParams): AxiosPromise<CampaignApi.GetCampaign.List.Response> {
    const stringifiedStatus = defineStatus(status);
    return this.instance.get('/campaign', {
      params: { page, size, status: stringifiedStatus, orderby },
    });
  }

  /** NOTE: trigger.nearby 를 바꿔야할때 0 | 1 로  전달할 것 */
  patch({ campaignId, data }: UpdateStatusParams): AxiosPromise<Campaign.CampaignDetail> {
    return this.instance.put(`/campaign/${campaignId}`, data);
  }

  getMonthlyStatus(): AxiosPromise<{ monthly_stat: Campaign.MonthlyStatus }> {
    return this.instance.get('/campaign/monthly_stat');
  }
}

export default Campaign;
