import type { AxiosInstance, AxiosPromise } from 'axios';

class Geofence {
  instance: AxiosInstance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(): AxiosPromise<Geofence.GeofenceStatus> {
    return this.instance.get('/geofence/state');
  }
}

export default Geofence;
