import styled from '@emotion/styled';
import { grayscale800, blue100, blue500, grayscale200, Button } from 'loplat-ui';

export interface Props {
  focused?: boolean;
}

export const StyledButton = styled(Button)<Props>`
  height: 2.375rem;
  border-color: ${grayscale200};
  color: ${grayscale800};
  background-color: white;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0.43rem 0.94rem;

  ${({ focused }) =>
    focused &&
    ` border: 1px solid ${blue500};
      font-weight: 500;
      color: ${blue500};
      background-color: ${blue100};`};
`;

export const NonStyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  small {
    margin-left: 0.2rem;
  }
`;
