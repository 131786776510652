import CryptoJS from 'crypto-js';
import CONFIG from '@config/';
import COOKIES from '@data/cookie';
import authCookie from './auth/authCookie';

/** PC version <-> mobile Version 로그인 공유 (redirect)를 위한 유틸 */
class ShareLogin {
  static #secret = CONFIG.KEYS.USER_SECRET;
  static #cookieKey = `${CONFIG.COOKIE_PREFIX_KEY}__${COOKIES.USER}`;

  static #encrypt = (user: string): string => {
    return CryptoJS.AES.encrypt(user, this.#secret).toString();
  };

  static #decrypt(user: string): string {
    return CryptoJS.AES.decrypt(user, this.#secret).toString(CryptoJS.enc.Utf8);
  }

  static clear(): void {
    authCookie.remove(this.#cookieKey);

    // NOTE: 임시로직 LX-2461
    if (CONFIG.MODE === 'production') {
      [location.host, 'x.loplat.com', '.x.loplat.com', 'beta.x.loplat.com'].forEach((domain) =>
        authCookie.remove(this.#cookieKey, { domain })
      );
    }
  }

  static ready = (email: string, password: string): void => {
    const userInfo = `${email} ${password}`;
    const encryptedUserInfo = this.#encrypt(userInfo);

    authCookie.set(this.#cookieKey, encryptedUserInfo);
  };

  /** pc version에서 저장된 UserInfo 를 가져옴 */
  static getUserInfoFromPCVersion(): string {
    const userInfo = authCookie.get(this.#cookieKey);
    if (!userInfo) return '';

    this.clear();
    return this.#decrypt(userInfo);
  }
}

export default ShareLogin;
