import React, { useCallback } from 'react';
import userInfo from '@recoil/userInfo.atoms';
import { useSetRecoilState } from 'recoil';
import { Button } from 'loplat-ui';
import { AuthUserApi } from '@api/index';
import * as S from '../styles';
import { useTranslation } from 'react-i18next';
import { gtagEvent } from '@function/gtag';

export interface Props {
  closeModal: () => void;
}

const LogOutModal = ({ closeModal }: Props): React.ReactElement => {
  const { t } = useTranslation('modal/common');
  const setUser = useSetRecoilState(userInfo);

  const onLogOutYes = useCallback(() => {
    closeModal();
    gtagEvent({ eventName: '로그아웃' });
    setUser(null);
    AuthUserApi.logout();
  }, [closeModal, setUser]);

  const onLogOutNo = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <>
      <S.ModalBody>
        <p>{t('logout')}</p>
      </S.ModalBody>

      <S.Footer>
        <Button color='ghost' onClick={onLogOutYes}>
          {t('yes')}
        </Button>
        <Button color='ghost' onClick={onLogOutNo}>
          {t('no')}
        </Button>
      </S.Footer>
    </>
  );
};

export default LogOutModal;
