import cryptoJS from 'crypto-js';
import CONFIG from '@config/index';

// NOTE: https://developers.google.com/tag-platform/gtagjs/reference#event
export const gtagEvent = ({ eventName, parameter }: EventProps) => {
  if (!parameter) window.gtag('event', eventName);
  else window.gtag('event', eventName, parameter);
};

export const gtagErrorCapture = (err: Error) => {
  window.gtag('event', 'exception', {
    description: err,
    fatal: fail,
  });
};

export const gtagLoginEvent = (user: User.Auth.UserInfo) => {
  if (CONFIG.KEYS.GA === '') return;
  // https://developers.google.com/analytics/devguides/collection/ga4/user-id?hl=ko&client_type=gtag
  // 실제 사용자 ID는 중요한 개인 정보이기때문에 반드시 암호화하여 사용해야 함 - 고객을 끌어오는 구글 애널리틱스4 306p
  const hashedUserId = cryptoJS.MD5(user.identity).toString();
  window.gtag('config', CONFIG.KEYS.GA, { user_id: hashedUserId });

  // https://developers.google.com/tag-platform/gtagjs/reference#set
  // https://developers.google.com/analytics/devguides/collection/ga4/user-properties?hl=ko&technology=websites
  window.gtag('set', 'user_properties', {
    company: user.company,
    membership: (user.membership ?? '').toString(),
    role: user.role,
  });
  window.gtag('event', '로그인');
};

type EventProps = {
  eventName: string;
  parameter?: { [k: string]: string | number | unknown };
};
