import CryptoJS from 'crypto-js';
import CONFIG from '@config/';
import jwtDecode from 'jwt-decode';

export const encryptRefreshToken = (token: User.Token.Base): string => {
  return CryptoJS.AES.encrypt(token.refresh_token, CONFIG.KEYS.SECRET).toString();
};

export const decryptRefreshToken = (token: User.Token.Base): string => {
  return CryptoJS.AES.decrypt(token.refresh_token, CONFIG.KEYS.SECRET).toString(CryptoJS.enc.Utf8);
};

export const decryptUserInfo = (token: User.Token.Base): User.Auth.UserInfo => {
  const accessToken: User.Token.DecryptedAccessToken = jwtDecode(token.access_token);
  return Object.assign(accessToken.user_claims, {
    identity: accessToken.identity,
    scope: accessToken.scope.split(' '),
    id: accessToken.sub,
  });
};
