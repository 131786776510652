import React, { useCallback } from 'react';
import { Button, WarningCircleOutlineIcon, blue500 } from 'loplat-ui';
import { preventRedirect } from '@function/redirection';
import * as S from '../styles';
import { Body, WarningText } from './styles';
import CONFIG from '@config/';
import { gtagEvent } from '@function/gtag';
import { useTranslation } from 'react-i18next';

export interface Props {
  closeModal: () => void;
}
const RedirectionModal = ({ closeModal }: Props): React.ReactElement => {
  const { t } = useTranslation('modal/common');
  const changeViewVersion = useCallback(() => {
    preventRedirect();
    closeModal();

    gtagEvent({ eventName: 'redirect_to_PC' });

    window.location.href = getDesktopUrl();
  }, [closeModal]);

  return (
    <>
      <Body>
        <WarningCircleOutlineIcon fillColor={blue500} size={32} suffixForId='warningIcon' />
        <WarningText>
          <strong>{t('redirection.0')}</strong>
          <br />
          {t('redirection.1')}
        </WarningText>
      </Body>
      <S.Footer>
        <Button color='ghost' onClick={changeViewVersion}>
          {t('yes')}
        </Button>
        <Button color='ghost' onClick={closeModal}>
          {t('no')}
        </Button>
      </S.Footer>
    </>
  );
};

export default RedirectionModal;

const getDesktopUrl = () => {
  const parsingUrl = (subdomain: string) => {
    const protocol = 'https://';
    const url = 'x.loplat.com';
    return `${protocol}${subdomain}${url}`;
  };
  if (CONFIG.MODE === 'localhost') return 'http://localhost:8081';
  if (CONFIG.MODE === 'staging') return parsingUrl('staging.');
  if (location.host.includes('beta')) return parsingUrl('beta.');
  return parsingUrl('');
};
