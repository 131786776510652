export default {
  // 쿠키 key
  SAVED_EMAIL: 'loplat-max-saved-email',
  REDIRECT: 'loplat-max-prevent-redirect',
  USER: 'secure_loplat',
  TOKEN: 'loplat-max-jwt-token',

  PREFIX: {
    STAGING: 'staging',
    BETA: 'beta',
  },
};
