import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CloseIcon, toast, IconButton } from 'loplat-ui';
import { StyledButton } from '@F/StyledButton/styles';
import { CampaignApi } from '@api/index';
import type { HTTPError } from '@TS/HttpError';
import type { UpdateStatusParams } from '@TS/campaigns/api';
import fetchingCampaignLoading from '@recoil/fetchingCampaignLoading.atom';
import campaignToChangeInfo from '@recoil/campaignToChangeInfo.atom';
import * as S from '../styles';
import { gtagEvent } from '@function/gtag';
import i18next from 'i18next';

interface Props {
  closeModal: () => void;
}

const StatusModal = ({ closeModal }: Props): React.ReactElement => {
  const { t } = useTranslation(['translation', 'campaign/status']);
  const setIsLoading = useSetRecoilState(fetchingCampaignLoading);
  const campaignToChange = useRecoilValue(campaignToChangeInfo);
  const queryClient = useQueryClient();

  const mutateCampaignStatus = useMutation(
    (data: UpdateStatusParams) => {
      return CampaignApi.patch(data);
    },
    {
      onSettled: () => {
        queryClient.refetchQueries({ stale: true });
        setIsLoading(false);
      },
      onError: (error) => {
        // NOTE: useMutation의 error타입이 unknown으로 정해져 있어
        // 타입가드를 대체하기 위한 손쉬운 방법으로 타입단언을 사용함
        const httpError = error as HTTPError;
        if (httpError.response?.data.error === 'NotAllowedError') {
          toast.danger(t('error_message.EXCESS_GEOFENCE_LIMIT'));
        } else {
          toast.danger(t('error_message.RETRY_FETCH_CAMPAIGN'));
        }
        mutateCampaignStatus.reset();
      },
      onMutate: () => {
        setIsLoading(true);
      },
    }
  );

  const changeCampaignStatus = useCallback(
    (statusNumber: UpdateStatusParams['data']['status']) => {
      if (!campaignToChange) return;
      const translator2Korean = i18next.getFixedT('ko', 'campaign/status', '');

      let changeNumber = statusNumber;
      if (campaignToChange.isTestCampaign) changeNumber = statusNumber === 1 ? 2 : statusNumber;

      const paramsData: UpdateStatusParams = {
        campaignId: campaignToChange.campaignId,
        data: {
          status: changeNumber,
        },
      };
      mutateCampaignStatus.mutate(paramsData);

      gtagEvent({
        eventName: '캠페인_상태_변경',
        parameter: { term: translator2Korean(changeNumber.toString()) },
      });

      closeModal();
    },
    [campaignToChange, closeModal, mutateCampaignStatus]
  );

  if (!campaignToChange) {
    closeModal();
    return <></>;
  }

  const { campaignName, status: target, campaign_type } = campaignToChange;

  return (
    <>
      <S.ModalTitle>
        <Trans
          parent='h3'
          ns='campaign/status'
          i18nKey='label'
          values={{ id: campaignName }}
          components={{ bold: <strong /> }}
        />
        <IconButton onClick={closeModal} color='ghost' aria-label='캠페인 상태변경 취소'>
          <CloseIcon size={16} />
        </IconButton>
      </S.ModalTitle>

      <S.ModalBody>
        <S.ButtonListWrapper>
          {((campaign_type === 0 ? [0, 1] : [10, 11]) as UpdateStatusParams['data']['status'][])
            .filter((status) => {
              // 테스트 상태를 진행 상태로 간주
              const testToCommon = target === 2 ? 1 : target;
              return status !== testToCommon;
            })
            .map((status) => (
              <StyledButton key={status} onClick={() => changeCampaignStatus(status)} fullWidth>
                {t(status.toString(), { ns: 'campaign/status' })}
              </StyledButton>
            ))}
        </S.ButtonListWrapper>
      </S.ModalBody>
    </>
  );
};

export default StatusModal;
