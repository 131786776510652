import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import CONFIG from './config';

const detectorOptions = {
  order: ['querystring', 'cookie', 'htmlTag', 'path', 'subdomain', 'navigator'],
  lookupCookie: `${CONFIG.COOKIE_PREFIX_KEY}__preferredLocale`,
  caches: ['cookie'],
  cookieMinutes: 365 * 24 * 60, // = one year
  cookieDomain: CONFIG.DOMAIN,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'ko',
    detection: detectorOptions,
    fallbackLng: 'ko',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
