import { Global, css } from '@emotion/react';
import { grayscale800, grayscale50 } from 'loplat-ui';
import React from 'react';

const GlobalStyle = (): JSX.Element => {
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          border: 0;
        }
        html {
          min-height: 100%;
          display: flex;
          flex-direction: column;
        }
        body {
          font-family: 'NanumSquareRound', sans-serif;
          background-color: ${grayscale50};
          color: ${grayscale800};
          flex-grow: 1;
        }
        p,
        h3 {
          white-space: pre-wrap;
        }
        ul {
          padding: 0;
          list-style: none;
        }
        button {
          background-color: transparent;
          font-family: inherit;
        }
      `}
    />
  );
};

export default GlobalStyle;
