import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import modalState from '@recoil/modal.atoms';
import * as S from './styles';

interface Props {
  children: React.ReactNode;
}

const Modal = ({ children }: Props): React.ReactElement | null => {
  const modalStatus = useRecoilValue(modalState);
  const closeModal = useResetRecoilState(modalState);

  useEffect(() => {
    const root = document.getElementById('root') as HTMLElement;
    const rootButtonList: NodeListOf<HTMLButtonElement> = root.querySelectorAll('button');
    const portal = document.getElementById('portal') as HTMLElement;

    if (modalStatus.isOpen) {
      document.body.style.overflow = 'hidden';
      root.setAttribute('aria-hidden', 'true');
      root.setAttribute('aria-disabled', 'true');
      rootButtonList.forEach((item) => item.setAttribute('tabindex', '-1'));
      portal.setAttribute('aria-hidden', 'false');
    } else {
      document.body.style.overflow = 'auto';
      root.setAttribute('aria-hidden', 'false');
      root.setAttribute('aria-disabled', 'false');
      rootButtonList.forEach((item) => item.removeAttribute('tabindex'));
      portal.setAttribute('aria-hidden', 'true');
    }
  }, [modalStatus]);

  if (!modalStatus.isOpen) return null;

  return ReactDOM.createPortal(
    <S.Overlay onClick={closeModal}>
      <S.ModalWrapper role='dialog' aria-haspopup='true' aria-modal='true'>
        {children}
      </S.ModalWrapper>
    </S.Overlay>,
    document.getElementById('portal') as HTMLElement
  );
};

export default Modal;
