import styled from '@emotion/styled';
import Z_INDEX from '@/style/zIndex';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEX.MODAL_BACKGROUND};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const ModalWrapper = styled.div`
  background-color: white;
  border-radius: 1rem;
  width: 100%;
  padding: 1.5rem 1rem;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.3);
`;
